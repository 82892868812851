import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/emilylouie/Documents/workspace2021/emilyslouie.github.io/src/components/projectLayout.js";
import { Link, graphql } from "gatsby";
import { Details, Blockquote } from "../components/details.js";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from "react-accessible-accordion";
import MoreProjects from "../components/moreProjects.js";
import snap from "../images/figma/before-after-snap.gif";
import slick from "../images/figma/slick-animation.gif";
export const pageQuery = graphql`
  query {
    allMdx(
      filter: { frontmatter: { company: { ne: null } } }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            shortTitle
            path
            type
            order
            tags
            company
            thumbnail {
              childImageSharp {
                gatsbyImageData(
                  height: 280
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <div className="header">
  <h1 className="purple">{props.pageContext.frontmatter.shortTitle}</h1>
  <h2>{props.pageContext.frontmatter.title}</h2>
    </div>
    <p>{`I joined the FigJam team at Figma as a software engineering intern in San Francisco, California. The team's mission is to help make FigJam the best online whiteboarding tool through key product pillars such as delight and collaboration. During my internship, I helped to launch 3 projects, one of which was highlighted for FigJam's first year anniversary.`}</p>
    <Details company={props.pageContext.frontmatter.company} role={props.pageContext.frontmatter.role} timeline={props.pageContext.frontmatter.timeline} skills={props.pageContext.frontmatter.tags} mdxType="Details" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8ab01183a30e0e0e12e0847b4104af26/9b379/header.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAACVElEQVQ4y3WSy67kNBCGz+PxTiwQYs2CBe/AaoQEDBJrRjBCaCQ03a1zOumkT6cTJ+n03fEt9ofs6KDRzJDor3LJ5S+VKj80uURkI2050m1n9c8jh+fZp/Uuxuq//ahDNeslFvlIk0keommLETNa3GSxzqKU4S5N8kpbbnfF7S4xxmC0wVrDuR85CY02GjXqBE3AOpMcSk0x7mifFKrylEf46Z0l7wJPbeDVX4q1mIhPCMnijMeqQHyttR8A15KhNCzOGdeLIpgwHwjRwvbg+f1Rsz/FyDNNHu99AsWMED4GZpJhq1kdCyYMVwGvv4ZrEyu6Q5gri4fHcURKmbxSCudc2vkM0LA45lg0lwp+/gqOxQ29eoW99RgXmJxFyjH18QUUgv+fCkvD8rRBB41TsPnDo0cPQfHrxvHd35LDbcSdK+rtE0PfUxQ1l5v6TIXrucJ/Dmscmu4Rvv8icMoDb4/w5RvPt+8CgwpM9x1dW9OIgWLbcL1ePwVG05eK9j7ggkstu/Rzw38pPN/8OdFcpxRfTxJ78Th9JHiVJv7JL8/3UOKdT2MNYYb5EOfoMc5jjE8Tbn6UbH8QDLu3nA9rvHcJOk0T3Va+AEe6UmGtS7CY4F3gou4sf7M8Lz1vuolBOvRJc2ouXDrmD/opXaOu6xgqQ7MeeWjyO/XmTH/o6fuOLqpraTtBVQqqSrATAtEK2r5BdAIhmpQTc9uupREN+/xIvb7xsM8ulI8dxUokbVYN+bImX9Tkq33SJsbLmuz9nvX7imwR/Z5sUbNZxvwmrXdPJ/4FLVjXQ/QG1HwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Header image of Figma intership experience",
            "title": "Header image of Figma intership experience",
            "src": "/static/8ab01183a30e0e0e12e0847b4104af26/1cfc2/header.png",
            "srcSet": ["/static/8ab01183a30e0e0e12e0847b4104af26/3684f/header.png 225w", "/static/8ab01183a30e0e0e12e0847b4104af26/fc2a6/header.png 450w", "/static/8ab01183a30e0e0e12e0847b4104af26/1cfc2/header.png 900w", "/static/8ab01183a30e0e0e12e0847b4104af26/9b379/header.png 951w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h4>{`What did I do?`}</h4>
    <br />
    <br />
    <p>{`Throughout my internship, I worked on 3 main projects:`}</p>
    <ol>
      <li parentName="ol">{`Creating a consolidated entry point for all of FigJam's resources (widgets, plugins, templates, and stickers)`}</li>
      <li parentName="ol">{`Refining the snapping and guideline logic in FigJam so that users have a more informative experience`}</li>
      <li parentName="ol">{`Spearheaded emotive reactions and custom CSS animations when keywords are typed into cursor chat for FigJam's one year anniversary`}</li>
    </ol>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5bd9e177cbf0ef1e37ad297bb3e43660/9b379/epic-adventure.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.22222222222222%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC90lEQVQozx3DXUxTZwCA4XNl4obLAgxY5Sespk6g0MLqDwUEooBS6apWzyrzdChrC9S6lYBhiTIQKGO2tRsWsNJVCqGjSmnnpqjBZVHMsmQzy26WLNntkl1xs2VLvnfJnuSRNtd/Zrx/iuCVG4RGZgh9PMMngwEigTjXP1skcitJePoL/IHb+AMxrl6e4nN/jInhEEH/IpO+KPMLDwn6F0gtbyCtTq/QtEOFXGbApt1Pp86I6XUNNmMbb1QbaWg7Qu3BJt6s2EtF8U7ys7PR6evJzi5Goz3IKVmh6cR5skqq+XDwGtIP8a+JWyykHE5WOjtZ6+klY1fwKz0UlBt5OauAV7a9iiqnCHWuirzXVFjtXvJ3t5BTZMA3dpmKjg+Q9l3E47uL9PuX3/BT41u8ONrIC9nEj+ZD/HrWzJLSxUvFeyhVV1CQq+KApoQui53Cot00Nplplgco0J/m0wGZQ7ILSXcBt/8x0p/3H/NvXyv/9B/nL5+TrYkeGDnLA6+TwhINDVVV1BpqsNTpGHUPUqKuolpXh6byCM0X5mmwXcR20oRU00dP6AnS1uYT/pjpZivyPsye4e+YA5ZcPBhzk5O1nfKiPKoqtewqzOekWcF0yo3rxBks7c1097uZ9HYzMDJB29UEvui3SN+n1xmytuCT27l+upWAtZWwvYVxTx/nzvVyKziFovTSbu1ibPoOQ6EUw53vErlkxfPeMTKTZhLTo8zNzHE7+RQpPZ8WHbvKhKzRC0upVnTsMQpzqV543nGJt7uGxM14RiytbYjoyrq4dG1B9I7OiY7DJlF/QCfqavaJhFcWQaVNaNRqMfCRX0jpyF1acnai6Gtx1B/GVraXY7mleGQH+83nsTqv4B0Pc9w1TPlRBwaLk3KtgW3bd1BZqaNBpyc/T4UkSXTb+5B+efabWA2viHvRjHi0uC7uR78Smdk7YiP1TKTWnotE4juRWn3+/2TyqYjHH4nl2D0xG1oQ8+GEmLuxLGI3kyIcjImH6U3xH5uRwf9T2AAOAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "A summary of my epic adventure at Figma",
            "title": "A summary of my epic adventure at Figma",
            "src": "/static/5bd9e177cbf0ef1e37ad297bb3e43660/1cfc2/epic-adventure.png",
            "srcSet": ["/static/5bd9e177cbf0ef1e37ad297bb3e43660/3684f/epic-adventure.png 225w", "/static/5bd9e177cbf0ef1e37ad297bb3e43660/fc2a6/epic-adventure.png 450w", "/static/5bd9e177cbf0ef1e37ad297bb3e43660/1cfc2/epic-adventure.png 900w", "/static/5bd9e177cbf0ef1e37ad297bb3e43660/9b379/epic-adventure.png 951w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p className="caption">A summary of my epic adventure at Figma</p>
    <p>{`It was a really cool experience getting to work so collaboratively with others and being staffed as a full-time engineer on the team. In these projects, I worked with 1-3 other software full-time engineers, 1 product designer, and 1 product manager.`}</p>
    <h2>{`Magic Modal`}</h2>
    <p>{`Initially, in FigJam, the toolbar had 4 different buttons to access the different resource modals of widgets, plugins, templates, and stickers. There was no way to search between the modals and overall made it confusing for users since there were too many entry points. In order to improve the overall experience, the team aimed to consolidate all of these modals into the "magic box" which encompasses all of the resources.`}</p>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6ba64829d87ea04fe5913125c8aaad3e/9b379/before-after-modal.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.44444444444444%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACOElEQVQoz1WSi27TMBSG+/C8A2+AhCYYCIGEAI0NjYndOnUbvaVNuzb3pEkc27m1/VCSsYEl61jW8XfO+X/3HEPRf1ty/abEnigcQ+LONLFVsbErwrUkdnP8pcI1JM5U4s01vpm3scltGE10Z4pec5hfaOxhTlnUeEbB/XFK/2bAdDShrLY0q97vyGtQck8cZqSxoCprRNQ18gRs6E31IJBIpajrGj/YMB2ZLIcmIrzj7vILg6MhajpDWBYyy5EyA3aopMIay0eg7oDeLGd8G5Fluk1iD6+uj7l9uESvTczBex4+fUC/fon4+pk4qchESlVsEUGFYzyP3mva7C4EIkloaPV2y8SyWD3MODs/w/Zt3NE99vkxmWnirwpGJxmeUbbTTW7c/4H+UuOaksiViCgnFYLocod3CTI8RVkHZCInFluEKrDGGR9fKMYnimhdcPVjwnIY481zes5UcXVQ0j8scaYaeyqxJ5L1JMM2MiJHE7k5oZ2xcbLW6SbHX0qcmSRYVPRPDRb3Ef686DScn+fML/J/7Ne4hm6/hZSSstLUdcmeHeGqy2vNnBct8Nf336xGaddh87jRwV88wuadpk4jxUqRxRlpotiEEckmwTM19kTjzQrMu5Cf3waMrix8s+g07KrpdlxvoRgf53gPis2ywLvdIYWDDN4RBFMcNySJJBu/28tR1BoSLMqn6Xp/3emcVqxHktAVOAPN4qgmcR3SxSGpZ7HxSmJfteYFdoq/yPHNZ1jT4R/fXjBIPha3TgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "The before and after of the modals in FigJam.",
            "title": "The before and after of the modals in FigJam.",
            "src": "/static/6ba64829d87ea04fe5913125c8aaad3e/1cfc2/before-after-modal.png",
            "srcSet": ["/static/6ba64829d87ea04fe5913125c8aaad3e/3684f/before-after-modal.png 225w", "/static/6ba64829d87ea04fe5913125c8aaad3e/fc2a6/before-after-modal.png 450w", "/static/6ba64829d87ea04fe5913125c8aaad3e/1cfc2/before-after-modal.png 900w", "/static/6ba64829d87ea04fe5913125c8aaad3e/9b379/before-after-modal.png 951w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p className="caption">The before and after of the modals in FigJam.</p>
    <p>{`For this project, I worked with Golf, Laura, and Annie for 6 weeks. I took ownership in creating the contents of the templates tab in the modal. The project was primarily done in React TypeScript and I learned some more about using React hooks and using functions written in the C++ layer in the web layer.`}</p>
    <br />
    <h2>{`Snapping`}</h2>
    <p>{`The next project I worked on was refining the snapping and guideline logic in FigJam. Previously, FigJam showed a simplified version of Figma's guideline logic by removing the crosses on the gaplinees, and removing the measurement labels. However, this was still quite complicated for the average FigJam user since there is less of a need to have precise measurements for diagramming as opposed to high fidelity design work. The goal of this project was to reduce the complexity of the snapping and guideline logic in FigJam so that is it easy to use.`}</p>
    <p>{`This project was scoped for 4 weeks, and I worked with Laura. I learned a lot about pair programming, the C++ layer of the codebase, and reading existing code that had been written many years ago. This project was one of the first times I was able to use my knowledge of data structures and algorithms in a real scenario which was interesting to see.`}</p>
    <p>{`Some of the modifications that I worked on were changing the colours of the lines to a more chill blue and purple as opposed to an aggressive red, adding perpendicular bars to the gaplines to make it easier identify that they are gaplines, and showing guidelines for only certain node types.`}</p>
    <img style={{
      display: "block",
      marginLeft: "auto",
      marginRight: "auto"
    }} src={snap} alt="The before and after of the snapping and guideline project." />
    <p className="caption" style={{
      textAlign: "center"
    }}>
  The before and after of the snapping and guideline project.
    </p>
    <h2>{`Fun Cursor Chat`}</h2>
    <p>{`The last project I worked on was quite ambiguous at first - with only the goal of creating something delighful and collaborative for FigJam's first year anniversary. This feature was to be launched on April 21st and demoed in a livestream to celebrate the product.`}</p>
    <p>{`At first, the project was scoped to recreate fun cursors which was an April Fun Day feature launched in 2020. However, with more consideration as to the objectives of the project, it was decided to put that feature on hold and instead create fun cursor chat. When users type in cursor chat and trigger certain keywords, emojis and animations are shown in real time to all users in the file.`}</p>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6b57060da1d6bbd2cfef599b9538a95a/9b379/fun-cursor-chat-mocks.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "21.777777777777775%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAz0lEQVQY012PyWrDQBBE9f8fkT9JgiCQS8gtB4OFY0teUOyZkdVjzSL1C3IcshQU/QqKhipOTcB3I71JiM3I6YKYiNiIuPzFJiA23Zzp596vPF/vEnYfKMy+J8o7s6bhxNit+JGS2jV/pf9A9cbqXdLCf3jempaXOgMJvzT05ROxfrjWw6FCHu+YLu6aY86Uy4Ak/X6Geb5HXkuGAIXbRQ7tyHI9MPQj513ELhrO2w3STYgZ6FYL+qNH3HidV20C9pjUz1PPqrbaqqtqtW3WTwlSMpOGjM3OAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Mockups of the different fun cursor chat options",
            "title": "Mockups of the different fun cursor chat options",
            "src": "/static/6b57060da1d6bbd2cfef599b9538a95a/1cfc2/fun-cursor-chat-mocks.png",
            "srcSet": ["/static/6b57060da1d6bbd2cfef599b9538a95a/3684f/fun-cursor-chat-mocks.png 225w", "/static/6b57060da1d6bbd2cfef599b9538a95a/fc2a6/fun-cursor-chat-mocks.png 450w", "/static/6b57060da1d6bbd2cfef599b9538a95a/1cfc2/fun-cursor-chat-mocks.png 900w", "/static/6b57060da1d6bbd2cfef599b9538a95a/9b379/fun-cursor-chat-mocks.png 951w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p className="caption">Mockups of the different fun cursor chat options</p>
    <p>{`I worked on this project with Laura and while the project was scoped for 4 weeks, 2 of those weeks were used to prototype fun cursors before it got scrapped and the remaining 2 weeks were used to build out fun cursor chat instead.`}</p>
    <p>{`For this project, I got to take ownership in setting up the triggering system in TypeScript, as well as building out some of the custom animations in CSS. One of my favourite animations that I worked on was the glare animation that shows up on keywords like "clean" or "slick". It was a lot of fun to build in an hour or two. I really enjoyed this project overall because of how I got to work closely with designers and product managers to refine the idea into a shippable product.`}</p>
    <img style={{
      display: "block",
      marginLeft: "auto",
      marginRight: "auto"
    }} src={slick} alt="The slick animation for fun cursor chat." />
    <p className="caption" style={{
      textAlign: "center"
    }}>
  The slick animation for fun cursor chat.
    </p>
    <h4>{`Wrapping it up`}</h4>
    <br />
    <br />
    <p>{`Working at Figma was one of my favourite experiences ever! I got to explore San Francisco, meet amazing people, have a lot of impact by shipping 3 projects, and learn so much while I was there. I'm so proud of what I got done and I can't wait to be back to continue the epic saga.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f2343f2232440d6b45437ef66db39dc6/c6671/wrapping-up.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "42.22222222222223%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAACTUlEQVQozy3IW0vTYQDA4ReESoRuAiGjIhRDM6fOv0eUtCmeUGfpzE3ddDLnuU2dm+kcm3M5m7mZE7YSm6aW2oHUBDtcJFQXklddRaBfoG/w66bn8hF2q5cbSTlIGbfIz1JRVTxOtrwSeXoB8rRcwv4wr5cCzE55OD46Zm9rg+XFRU5PTjnYeoXZ0MOP798I+gJ83PuMaKjTIoRAiDPkZPqx236TkDj+/wSWfisT9ztoKClkbMTMQHMtZoOW4JNpNkIe6suLGO7vpuWuCu/0Y4TbMUOxQkGUiKO65Qv20F9Kq9aJiY5FiCi6jTY2/T5cTZV0tTair6/m0aAGZWUJnW2thIJWhrp7Maq1KCvuIGam/GhbOpBSsmiusVNRfUhDjZuyvHwuxl7APjJG2OvE1afDae7E57ARnhzGOWpCr1Zh6FBTcbuQPl0TS/N+RH/PKFcuXSbuWhLnY+JJTiiir6WR1ORkTD3PWZjbwjHQjVOrxWnpY9jQyO6ShY1IkFnPFI5RG52tarZXIpz8OUFYTA409XrUjWMky8rJTJXIk+S4Bg28XVnk/eY2ywE3IZ+btcgCxioF885RDr/u82Y1zMvQQz7t7zI3Mc6vo5+Ihek1NMpBdPc8lBXXkZuloLxEiXNIT2TexIfNdXIzMpHfTCJdlsL1+Ks8GOhl7eks065hMmQpJCYmcu5sNMaOLsSzwCqdzW30tutob6qlp03DpNVKYMrGzgsvB+92KCwoJT1NIiMtixJFGc4RC8u+SfweBzKZRI6UhyTPRq3S8Q9p5GXdlnrSWgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Shoutout to the rest of the Figma winterns and to the FigJamFam!",
            "title": "Shoutout to the rest of the Figma winterns and to the FigJamFam!",
            "src": "/static/f2343f2232440d6b45437ef66db39dc6/1cfc2/wrapping-up.png",
            "srcSet": ["/static/f2343f2232440d6b45437ef66db39dc6/3684f/wrapping-up.png 225w", "/static/f2343f2232440d6b45437ef66db39dc6/fc2a6/wrapping-up.png 450w", "/static/f2343f2232440d6b45437ef66db39dc6/1cfc2/wrapping-up.png 900w", "/static/f2343f2232440d6b45437ef66db39dc6/c6671/wrapping-up.png 1129w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p className="caption">
  Shoutout to the rest of the Figma winterns and to the FigJamFam!
    </p>
    <br />
    <h4>{`More case studies`}</h4>
    <MoreProjects projects={props.data.allMdx.edges} current={props.pageContext.frontmatter.order} mdxType="MoreProjects" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      